import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Popover,
  Divider,
  MenuItem,
  ListItemText,
} from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { useStore } from 'src/mobx-store';
import {
  UserBoxButton,
  UserAvatar,
  MenuUserBox,
  UserBoxText,
  UserBoxLabel,
  UserBoxDescription,
  MenuListWrapper,
} from '../style';

const HeaderUserbox = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { accountStore } = useStore();
  const { account } = accountStore;
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const menuList = [
    {
      name: '개인 설정',
      linkTo: '/setting/account',
    },
    {
      name: '계정 연동',
      linkTo: '/setting/social-connection',
      disable: true,
    },
    {
      name: '비밀번호 변경',
      linkTo: '/setting/password',
      disable: true,
    },
    {
      name: '사업자 설정',
      linkTo: '/setting/company',
    },
    {
      name: '환경 설정',
      linkTo: '/setting/preference',
      disable: true,
    },
    {
      name: '결제 정보',
      linkTo: '/setting/subscription',
      disable: true,
    },
    {
      name: '마켓',
      linkTo: '/setting/market',
      disable: true,
    },
    {
      name: '이용약관',
      linkTo: '/setting/terms',
      disable: true,
    },
    {
      name: '도움말',
      linkTo: '/setting/help',
      disable: true,
    },
  ];

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={account.username} src={account.profileImageUrl} />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox display="flex">
          <Link to="/setting/account">
            <Avatar
              variant="rounded"
              alt={account.username}
              src={account.profileImageUrl}
            />
          </Link>
          <UserBoxText>
            <Link to="/setting/account">
              <UserBoxLabel variant="body1">{account.username}</UserBoxLabel>
            </Link>
            <Link to="/setting/account">
              <UserBoxDescription variant="body2">
                {account.email}
              </UserBoxDescription>
            </Link>
          </UserBoxText>
        </MenuUserBox>
        <Divider />
        <MenuListWrapper disablePadding>
          {menuList.map((menu) => (
            <MenuItem component={Link} to={menu.linkTo} disabled={menu.disable}>
              <ListItemText primary={t(menu.name)} />
              <ChevronRightTwoToneIcon />
            </MenuItem>
          ))}
        </MenuListWrapper>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t('로그아웃')}
          </Button>
        </Box>
      </Popover>
    </>
  );
});

export default HeaderUserbox;
